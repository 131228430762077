export const environment = {
  production: true,

  apiURL: "https://api.voicebox.marketing.delivery/",
  siteURL: 'https://voicebox.marketing.delivery/',
  formBuilderURL: 'https://formeditor.voicebox.marketing.delivery/formeditor.html',
  formPublishURL: 'https://forms.voicebox.marketing.delivery/?',
  emailBuilderURL: 'https://emaileditor.voicebox.marketing.delivery/editor.html',

  sessionIdlePeriod: 3600,
  sessionTimeoutPeriod: 30,
  maxClientBuckets: 2,
  vehicleValidationAPIUrl: 'https://vehiclevalidator.marketing.delivery/regnbr/json',
  vehicleValidationAPIKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Ik1hcmtldGluZ0RlbGl2ZXJ5VXNlciIsInBhc3N3b3JkIjoiVSZrUnYzWWhiQ0FCbnhCZ3dwISFQVm5pV09Cak5AUjcjcE9uNzJtIVlYMFlPUUxeQVpObXBxZExkSVQ0VWZmI3l2dFdhbjBJIiwiaWF0IjoxNTg4MjU0MzYzfQ.ALiyLdz-uLXoL2aW3_6b8GJrp7zT3l3QjfO-MMZ6z_Y',

  unlayerS3Bucket: 'voiceboxmediaserver'
};
